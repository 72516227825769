<template>
  <div class="card card-custom gutter-b p-5">
    <div class="py-2">
      <p class="float-left font-weight-bolder font-size-h3 text-dark-75">
        {{ namaKegiatan }}
      </p>
      <b-button class="float-right" variant="success" @click="showModal">
        Tambah Pendaftar
      </b-button>
    </div>
    <div style="overflow-x: hidden; overflow-y: hidden" class="pt-2">
      <table
        id="example"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width: 10px">No</th>
            <th>Nama Peserta</th>
            <th>NIP</th>
            <th>DPMPTSP</th>
            <th>Jenis Kelamin</th>
            <th>Kartu Identitas</th>
            <th>Status Verifikasi</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in daftarPeserta" :key="row.id">
            <td>{{ index + 1 }}</td>
            <td>{{ row.nama_user }}</td>
            <td>{{ row.nip }}</td>
            <td>{{ row.ptsp }}</td>
            <td>{{ row.jenis_kelamin }}</td>
            <td class="text-center" ><b-button variant="primary" v-b-tooltip.hover
              title="Lihat Kartu Identitas" @click="lihatIdentias(row.id_user)"><i class="fa fa-address-card"></i></b-button></td>
            <td>
              <b-badge
                variant="warning"
                v-if="row.status_verifikasi == 'Menunggu Verifikasi'"
              >
                Menunggu Verifikasi
              </b-badge>
              <b-badge
                variant="success"
                v-if="row.status_verifikasi == 'Sudah Terverifikasi'"
              >
                Sudah Terverifikasi
              </b-badge>
            </td>
            <td>
              <b-button
                class="m-1"
                v-if="row.status_verifikasi == 'Menunggu Verifikasi'"
                variant="success"
                size="sm"
                @click="verifikasiData(row.id)"
                v-b-tooltip.hover
                title="Verifikasi"
                ><i class="fa fa-check"></i
              ></b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="deleteData(row.id)"
                class="m-1"
                v-b-tooltip.hover
                title="Hapus"
                ><i class="flaticon2-trash"></i
              ></b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal id="modal-tambah" title="Tambah Peserta" hide-footer>
      <div class="form-group">
        <s-select
          :options="dataPeserta"
          v-model="form.id_user"
          :reduce="(data) => data.id"
          label="name"
          placeholder="Pilih Peserta"
        ></s-select>
      </div>
      <div>
        <b-button class="float-right" variant="success" @click="submitData">
          Tambah
        </b-button>
        <b-button
          class="float-right mr-2"
          variant="danger"
          @click="$bvModal.hide('modal-tambah')"
        >
          Batal
        </b-button>
      </div>
    </b-modal>

    <b-modal id="modal-kartuidentitas" title="Kartu Identitas Peserta" hide-footer size="lg">
      <div class="form-group" v-if="kartu_identitas == null || kartu_identitas == ''">
        Tidak Ada Kartu Identitas!
      </div>
      <div v-else>
        <img width="100%" :src="kartu_identitas" alt="">
        
      </div>
      <div>
        
        <b-button
          class="float-right mr-2"
          variant="danger"
          @click="$bvModal.hide('modal-kartuidentitas')"
        >
          Tutup
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import Swal from "sweetalert2";

export default {
  name: "detailKegiatan",
  components: {
    "s-select": VueSelect,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      daftarPeserta: [],
      namaKegiatan: "",
      dataPeserta: [],
      role: "admin_bimtek",
      form: {},
      statusVerifikasi: [
        { value: "Menunggu Verifikasi", text: "Menunggu Verifikasi" },
        { value: "Sudah Terverifikasi", text: "Sudah Terverifikasi" },
      ],
      kartu_identitas: null,
    };
  },
  mounted() {
    this.getdata();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detail Pendaftar" }]);
  },
  methods: {
    getdata() {
      $("#example").DataTable().destroy();
      this.getNamaKegiatan();
      this.loaddata();
      this.getPeserta();
    },
    loaddata() {
      this.axios
        .get(
          `${this.url}/jadwal_peserta?filter=id_kegiatan,=,${this.$route.params.id}`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          const data = response.data.data;
          this.daftarPeserta = data;
          this.initDatatable();
        });
    },
    getNamaKegiatan() {
      this.axios
        .get(
          `${this.url}/jadwal_kegiatan?filter=id,=,${this.$route.params.id}`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          const data = response.data.data;
          this.namaKegiatan = data[0].nama_kegiatan;
        });
    },
    getPeserta() {
      this.axios
        .get(`${this.url}/users?filter=id_role,=,peserta`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataPeserta = response.data.data;
        });
    },
    showModal() {
      this.$bvModal.show("modal-tambah");
    },
    submitData() {
      this.form.id_kegiatan = this.$route.params.id;
      this.axios
        .post(
          `${this.url}/jadwal_peserta?filter=status_verifikasi,=,Menunggu Verifikasi`,
          this.form,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          Swal.fire("Sukses", "Pendaftar berhasil ditambah", "success");
          this.$bvModal.hide("modal-tambah");
          this.form = {};
          this.getdata();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            Swal.fire("Gagal", "Pendaftar sudah ditambah", "error");
          } else {
            Swal.fire("Gagal", "Terjadi Kesalahan", "error");
          }
        });
    },

    verifikasiData(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Verifikasi!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .put(
              `${this.url}/jadwal_peserta/${id}`,
              { status_verifikasi: "Sudah Terverifikasi" },
              { headers: { xth: this.token } }
            )
            .then((response) => {
              Swal.fire({
                title: "Berhasil!",
                text: "Akun berhasil diverifikasi",
                icon: "success",
              });
              this.getdata();
            })
            .catch((error) => {
              Swal.fire({
                title: "Gagal!",
                text: "Terjadi kesalahan saat memverifikasi akun",
                icon: "error",
              });
            });
        }
      });
    },
    deleteData(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(`${this.url}/jadwal_peserta/${id}`, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Berhasil!",
                text: "Data berhasil dihapus.",
                icon: "success",
              });
              this.getdata();
            });
        }
      });
    },
    initDatatable(nama_kegiatan) {
      setTimeout(() => {
        const t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "excel",
              text: "Excel",
              title: "DAFTAR PENDAFTAR BIMTEK",
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5],
                orthogonal: "export",
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return "Daftar Pendaftar Bimtek_" + date;
              },
            },
          ],
        });
        // t.on("order.dt search.dt", function () {
        //   t.column(0, { search: "applied", order: "applied" })
        //     .nodes()
        //     .each(function (cell, i) {
        //       cell.innerHTML = i + 1;
        //     });
        // }).draw();
      }, 300);
    },

    lihatIdentias(id){
      this.axios
        .get(`${this.url}/users/${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kartu_identitas = response.data.data.ijazah;
          this.$bvModal.show("modal-kartuidentitas");
        });
    }
  },
};
</script>
